$(function () {
    $.validator.messages = {
        required: 'Поле обязательно для заполнения пароль. Проверьте и повторите вход',
        required: 'Это поле необходимо заполнить.',
        remote: 'Пожалуйста, введите правильное значение.',
        email: 'Пожалуйста, введите корректный адрес электронной почты.',
        url: 'Пожалуйста, введите корректный URL.',
        date: 'Пожалуйста, введите корректную дату.',
        dateISO: 'Пожалуйста, введите корректную дату в формате ISO.',
        number: 'Пожалуйста, введите число.',
        digits: 'Пожалуйста, вводите только цифры.',
        creditcard: 'Пожалуйста, введите правильный номер кредитной карты.',
        equalTo: 'Пожалуйста, введите такое же значение ещё раз.',
        extension: 'Пожалуйста, выберите файл с правильным расширением.',
        maxlength: $.validator.format('Пожалуйста, введите не больше {0} символов.'),
        minlength: $.validator.format('Пожалуйста, введите не меньше {0} символов.'),
        rangelength: $.validator.format('Пожалуйста, введите значение длиной от {0} до {1} символов.'),
        range: $.validator.format('Пожалуйста, введите число от {0} до {1}.'),
        max: $.validator.format('Пожалуйста, введите число, меньшее или равное {0}.'),
        min: $.validator.format('Пожалуйста, введите число, большее или равное {0}.')
    };

    $('#contact-form').validate({
        errorPlacement: () => { },
        submitHandler: function (form) {
            $(form).find('.formarea__submit').addClass('disabled');

            const data = new FormData();
            data.append('name', $(form).find('[name="contact-form-name"]').val());
            data.append('email', $(form).find('[name="contact-form-email"]').val());
            data.append('message', $(form).find('[name="contact-form-message"]').val());

            $.ajax({
                method: 'POST',
                url: $(form).attr('action'),
                enctype: 'multipart/form-data',
                cache: false,
                contentType: false,
                processData: false,
                data: data,
                success: function (data) {
                    data = JSON.parse(data);
                    $(form).find('.formarea__response').removeClass('error').text(data.response);
                    $(form)[0].reset();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log(textStatus, errorThrown);
                    $(form).find('.formarea__response').addClass('error').text(errorThrown);
                },
                complete: function (data) {
                    $(form).find('.formarea__submit').removeClass('disabled');
                    $(form).find('.formarea__response').removeClass('hide');
                    setTimeout(() => {
                        $(form).find('.formarea__response').removeClass('error').addClass('hide').text('');
                    }, 5000);
                }
            });
        },
    });
});