$(function () {
	$('.header__burger').on('click', function (e) {
		e.stopPropagation();
		$('body').toggleClass('show-menu');
	});

	$('body').on('click', function (e) {
		if (!e.target.closest('.header__menu')) {
			$('body').removeClass('show-menu');
		}
	});

	$('.scroll-link').on('click', function (e) {
		e.preventDefault();
		$('body').removeClass('show-menu');

		const DELTA_OFFSET = $(this).data('offset') ? parseInt($(this).data('offset')) : 40;
		const offset = $($(this).attr('href')).offset().top - $('.header').height() - DELTA_OFFSET;
		$('html').animate({ scrollTop: offset + 'px' }, '300');
	});

	const initSlider = () => {
		if ($(window).width() < 768) {
			if (!$(".about__text").hasClass("slick-initialized")) {
				$(".about__text").slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: true,
					adaptiveHeight: true,
					speed: 600,
					cssEase: 'ease',
					arrows: false,
					dots: true,
					customPaging: () => { }
				});
			}
		} else {
			if ($(".about__text").hasClass("slick-initialized")) {
				$(".about__text").slick("unslick");
			}
		}
	};

	initSlider();
	$(window).on("resize", () => initSlider());
});